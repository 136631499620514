<template>
  <div class="container">
    <div class="">
      <div class="flex flex-row items-center mb-6" style="margin-top: 80px;">
        <div class="flex flex-row items-center w-1/2">
          <div class="border-red-600 border-r-4 h-10 w-1 mr-2"></div>
          <div class="sm:text-lg md:text-2xl lg:text-3xl font-bold">
            ประกาศเปิดรับข้อเสนอโครงการ
          </div>
        </div>
      </div>
    </div>

    <div class="cardCustom mb-8">
      <div class="pr-8 pl-8 pb-8 pt-12">
        <div class="content mb-2">
          ประกาศเปิดรับข้อเสนอโครงการหรือกิจกรรมของกองทุนฯ ประจำปีงบประมาณ
          พ.ศ.2564 ครั้งที่ 1
        </div>

        <div class="mb-6">
          <img
            src="@/assets/mog/Artboard11.jpg"
            class="w-full h-full object-contain"
          />
        </div>

        <div class="law">
          <div class="pb-2">
            1. ประกาศคณะกรรมการบริหารกองทุนฯ เรื่อง
            การเปิดรับข้อเสนอโครงการหรือกิจกรรมของกองทุนพัฒนาดิจิทัลเพื่อเศรษฐกิจ
            และสังคมประจำปีงบประมาณ พ.ศ2564 ครั้งที่ 1
            และหลักเกณฑ์การพิจารณาโครงการหรือกิจกรรมที่ขอรับการส่งเสริมสนับสนุน
            ให้ความช่วยเหลือและการให้ทุนอุดหนุนการวิจัยและพัฒนาและเกณฑ์การประเมินคะแนน
          </div>

          <div class="pb-2">
            2. คู่มือรับทุนกองทุนพัฒนาดิจิทัลเพื่อเศรษฐกิจและสังคม
          </div>

          <div class="pb-2">
            3.
            รายการเอกสารหลักฐานประกอบการเสนอขอรับการส่งเสริมสนับสนุนจากกองทุนฯ
            ประจำปี พ.ศ. 2564 ครั้งที่ 1
          </div>
        </div>

        <div class="flex flex-row items-center mb-6 mt-6">
          <div class="flex flex-row items-center w-3/4">
            <div class="canClick">
              สามารถคลิกเข้าสู่ระบบยื่นรายละเอียดขอรับทุนปีงบประมาณ พ.ศ. 2564
              ครั้งที่ 1 โครงการหรือกิจกรรม ตามมาตรา 26(1) และ (2) ได้ที่นี่
            </div>
          </div>

          <div class="text-right w-1/4" style="color: #9c6de8">
            <a href="/login">
              <button>คลิก</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "request",
};
</script>

<style scoped>
.container {
  font-family: KanitFont;
}

.cardCustom {
  background: rgba(199, 179, 232, 0.1);
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.content {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
}

.law {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  text-decoration-line: underline;

  color: #ab84e8;
}

.canClick {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
}

button {
  width: 194px;
  height: 53px;
  left: 1264px;
  top: 921px;

  background: #7b55b5;
  border-radius: 5px;

  font-size: 24px;
  line-height: 36px;
  color: #FFFFFF;
}
</style>